import type { Dictionary, EntityState, PayloadAction } from "@reduxjs/toolkit";
import { createAction, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import type { IdentityTypeMap } from "@somewear/api";
import { IdentityRecord, IdentityType } from "@somewear/api";
import type { IWorkspaceAsset } from "@somewear/model";
import { workspaceActions } from "@somewear/workspace";
import isEqual from "fast-deep-equal";
import { original } from "immer";

import {
	assetActions,
	emitAddUserAccountFromServer,
	emitUserAccountChangeFromServer,
} from "./asset.actions";
import type { AssetsState } from "./assetsState";

export interface IWorkspaceIntegrationAsset extends IWorkspaceAsset {
	inboundEnabled: boolean;
	outboundEnabled: boolean;
}

export const emitBulkUpsertAccount = createAction<IWorkspaceAsset[]>("account/upsert/bulk");

export const getDisplayNameForWorkspaceAsset = (asset?: IWorkspaceAsset) => {
	return asset !== undefined ? asset.fullname?.replaceIfEmpty(asset.email) : "";
};

const adapter = createEntityAdapter<IWorkspaceAsset>({
	selectId: (user) => user.id,
	sortComparer: (a, b) => {
		if (a.isNew && b.isNew) {
		} else if (a.isNew) {
			return -1;
		} else if (b.isNew) {
			return 1;
		}

		const aName = a.fullname.toLowerCase();
		const bName = b.fullname.toLowerCase();
		if (aName && bName) {
			if (aName < bName) return -1;
			else if (aName > bName) return 1;
		} else if (aName) {
			return -1;
		} else if (bName) {
			return 1;
		}

		if (a.email && b.email) {
			if (a.email < b.email) return -1;
			else if (a.email > b.email) return 1;
		} else if (a.email) {
			return 1;
		} else if (b.email) {
			return -1;
		}
		return 0;
	},
});

// Rename the exports for readability in component usage
export const {
	selectAll: selectAllWorkspaceAssets,
	selectById: selectWorkspaceAssetById,
	selectEntities: selectWorkspaceAssetEntities,
} = adapter.getSelectors((state: AssetsState) => state.workspaceAssets);

export const assetSlice = createSlice({
	name: "workspaceAssets",
	initialState: adapter.getInitialState(),
	reducers: {
		setUserVisibility(state, action: PayloadAction<{ userId: string; visibility?: boolean }>) {
			const user = state.entities[action.payload.userId];
			if (user === undefined) return;
			if (action.payload.visibility === undefined) {
				user.visibilityOverride = undefined;
			} else {
				user.visibilityOverride = action.payload.visibility;
			}
			adapter.upsertOne(state, user);
		},
		hideAllUsers(state, action: PayloadAction<string | undefined>) {
			const users = adapter
				.getSelectors()
				.selectAll(state)
				.map((user) => {
					return { ...user, visibilityOverride: false };
				});
			adapter.upsertMany(state, users);
		},
		showAllUsers(state) {
			const users = adapter
				.getSelectors()
				.selectAll(state)
				.map((user) => {
					return { ...user, visibilityOverride: true };
				});
			adapter.upsertMany(state, users);
		},
	},
	extraReducers: (builder) => {
		/*builder.addCase(workspaceActions.fetchAccounts.fulfilled, (state, action) => {
			adapter.upsertMany(state, action.payload.data);

			const storeUserIds = adapter
				.getSelectors()
				.selectAll(state)
				.map((user) => user.id);
			const latestUserIds = action.payload.data.map((user) => user.id);
			const removedUserIds = _.difference(storeUserIds, latestUserIds);
			adapter.removeMany(state, removedUserIds);
		});*/
		builder.addCase(assetActions.fetchAssets.fulfilled, (state, action) => {
			adapter.upsertMany(state, action.payload.data.usersList);

			const identityDict: Dictionary<IdentityRecord.AsObject | undefined> = {};
			const assetTypeDict: Dictionary<IdentityTypeMap[keyof IdentityTypeMap] | undefined> =
				{};

			action.payload.data.identitiesList.forEach((identity) => {
				let type: IdentityTypeMap[keyof IdentityTypeMap] | undefined;
				switch (identity.type) {
					case IdentityRecord.Type.USER:
						type = IdentityType.USER;
						break;
					case IdentityRecord.Type.RESOURCE:
						type = IdentityType.RESOURCE;
						break;
					case IdentityRecord.Type.DEVICE:
						type = IdentityType.DEVICE;
						break;
					case IdentityRecord.Type.INTEGRATION:
						type = IdentityType.INTEGRATION;
						break;
				}
				assetTypeDict[identity.id] = type;
				identityDict[identity.id] = identity;
			});

			const members = action.payload.data.accountsList
				.flatMap((account) => {
					const identity = identityDict[account.identityId];
					const type = assetTypeDict[account.identityId];
					if (identity === undefined || type === undefined) return undefined;
					const asset: IWorkspaceAsset = {
						id: account.id,
						workspaceId: account.workspaceId,
						workspaceRole: account.workspaceRole,
						fullname: identity.fullName,
						email: identity.email,
						identityId: identity.id,
						isArchived: false,
						type: type,
					};
					return asset;
				})
				.mapNotNull((it) => it);

			adapter.upsertMany(state, members);
		});

		/*builder.addCase(
			assetActions.add.pending,
			(state, action: PayloadAction<IPendingPayload<string[]>>) => {
				const pendingUsers: UserResponse.AsObject[] = action.payload.data.map(email => {
					const user = new UserResponse().toObject();
					user.id = email;
					user.email = email;
					return user;
				});

				adapter.upsertMany(state, pendingUsers);
			}
		);*/
		builder.addCase(assetActions.add.fulfilled, (state, action) => {
			if (action.payload.data.usersList !== undefined) {
				adapter.upsertMany(state, action.payload.data.usersList);
			}
		});

		const handleArchiveOrRemoveFulfilled = (state: EntityState<IWorkspaceAsset>, action: PayloadAction<{ data: { usersList: IWorkspaceAsset[] } }>) => {
			const archivedUserIds = action.payload.data.usersList.map((user) => user.id);
			const users = archivedUserIds.mapNotNull((userId) => state.entities[userId]);
			users.forEach((user) => (user.isArchived = true));
			adapter.upsertMany(state, users);
		};

		builder.addCase(assetActions.archive.fulfilled, handleArchiveOrRemoveFulfilled);
		builder.addCase(assetActions.remove.fulfilled, handleArchiveOrRemoveFulfilled);
		builder.addCase(assetActions.assignRole.fulfilled, (state, action) => {
			if (action.payload.data.user !== undefined)
				adapter.upsertOne(state, action.payload.data.user);
		});
		builder.addCase(emitUserAccountChangeFromServer, (state, action) => {
			const previousDraft = state.entities[action.payload.id];
			console.log({ previousDraft, payload: action.payload });
			if (previousDraft !== undefined) {
				const previousObject = original(previousDraft);
				const matches = isEqual(previousObject, action.payload);
				// don't update anything if there are no changes
				if (matches) return;
			}

			adapter.upsertOne(state, action.payload);
		});

		builder.addCase(emitAddUserAccountFromServer, (state, action) => {
			console.log("added user");
			const userResponse: IWorkspaceAsset = action.payload;
			if (!action.payload.isArchived) {
				// user is not archived, mark them as new and upsert them
				userResponse.isNew = true;
				adapter.upsertOne(state, userResponse);
			}
		});
		builder.addCase(assetActions.update.fulfilled, (state, action) => {
			const updatedUserAccounts = action.payload.data.updatedUserAccounts?.responsesList;
			if (updatedUserAccounts !== undefined) adapter.upsertMany(state, updatedUserAccounts);
		});
		builder.addCase(workspaceActions.toggleIntegration.fulfilled, (state, action) => {
			adapter.upsertOne(state, action.payload.data);
		});
		builder.addCase(workspaceActions.bulkToggleIntegrations.fulfilled, (state, action) => {
			adapter.upsertMany(state, action.payload.data);
		});
		builder.addCase(emitBulkUpsertAccount, (state, action) => {
			adapter.upsertMany(state, action.payload);
		});
	},
});
